import React, { Component } from "react";
import EventsWithImage from "../components/EventsWithImage";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import SEO from "../components/Seo";
import SupportedLogos from "../components/SupportedLogos";
import AboutHero from "../images/apecChallenges/apec-challenge.jpg";

export default class ApecChallenges extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - APEC App Challenges"
          description="APEC App Challenge is a  joint initiative of the APEC Secretariat, The Asia Foundation, and Google, focusing on supporting and making a positive impact in APEC economies."
        />
        <Hero
          title=""
          titleBlue="APEC App Challenges"
          description="The APEC App Challenge is a joint initiative of the APEC Secretariat, The Asia Foundation, and Google focused on supporting and making a positive impact in APEC economies. Participating teams build applications or solutions that help address each year’s challenges. The App Challenge is held each year between May and June."
          addDescription="Participation in the APEC App Challenge is open to nationals from all member economies."
          image={AboutHero}
        />
        <EventsWithImage />
        <SupportedLogos
          description="Text goes here."
          svgIcon="https://tailwindui.com/img/logos/workcation-logo-indigo-900.svg"
        />
        <Footer />
      </div>
    );
  }
}
